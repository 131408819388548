import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

import Home from './pages/Home';
import Contact from './pages/Contact';

import { Bars3Icon } from '@heroicons/react/24/solid';

export default function App() {
  return (
    <Router>
      <div className="bg-black h-dvh flex justify-center items-center">
        <div className="flex flex-col gap-2">
          <a href="/" className="text-white">caricamento Home senza Link</a>
          <Link to="/" className="text-white">caricamento Home con link</Link>

          <a href="/contact" className="text-white">caricamento Contact senza Link</a>
          <Link to="/contact" className="text-white">caricamento contact con link</Link>
        </div>

        <Eden />
      </div>
    </Router>
  );
}

function Eden() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
  );
}